import React from "react";
import PropTypes from "prop-types";
import "./alert.scss";

import Icon from "../../atoms/icon/icon";
import Title from "../../atoms/title/title";
import { buildClassName } from "../../../helpers/className";

export default function Alert({
    variant,
    title = "",
    className = "",
    innerHtml = "",
    children = null,
    onDismiss = undefined,
    breakWords = false,
}) {
    return (
        <div
            className={buildClassName(
                "alert",
                variant && `alert-${variant}`,
                onDismiss && "alert-dismissible",
                !title && "alert-notitle",
                breakWords && "break-words",
                className,
            )}
            role="alert"
        >
            <div className="alert-header">
                <div>
                    <Icon variant={variant} size={2} />
                </div>
                {title ? (
                    <Title variant="h3" size="medium" weight="bold">
                        {title}
                    </Title>
                ) : (
                    <AlertDescription innerHtml={innerHtml}>
                        {children}
                    </AlertDescription>
                )}
                {onDismiss ? (
                    <button
                        className="close"
                        onClick={onDismiss}
                        type="button"
                        aria-label="close"
                    >
                        <Icon variant="close" />
                    </button>
                ) : null}
            </div>
            {title ? (
                <AlertDescription innerHtml={innerHtml}>
                    {children}
                </AlertDescription>
            ) : null}
        </div>
    );
}

Alert.propTypes = {
    variant: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    innerHtml: PropTypes.string,
    breakWords: PropTypes.bool,
    onDismiss: PropTypes.func,
};

function AlertDescription({ children, innerHtml }) {
    return (
        <div className="alert-description">
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={
                    innerHtml ? { __html: innerHtml } : undefined
                }
            >
                {innerHtml ? undefined : children}
            </div>
        </div>
    );
}

AlertDescription.propTypes = {
    children: PropTypes.node,
    innerHtml: PropTypes.string,
};
