import * as React from "react";
import PropTypes from "prop-types";

export default function FormLabel({
    className = "",
    htmlFor = "",
    id = "",
    helper = null,
    children,
}) {
    return (
        <div className="form-label">
            <label id={id} className={className} htmlFor={htmlFor}>
                {children}
            </label>
            {helper && <div className="form-helper">{helper}</div>}
        </div>
    );
}

FormLabel.propTypes = {
    id: PropTypes.string,
    helper: PropTypes.node,
    htmlFor: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
