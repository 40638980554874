import { useCallback, useMemo, useState } from "react";

/**
 * @typedef {object} useURLReturns
 * @property {URL} url
 * @property {object.<string,string>} searchParams
 * @property {function} refreshURL
 * @property {(...params: string[]) => boolean} hasAllParams
 */

/**
 * Hook to get URL data (optimised)
 * @return {useURLReturns}
 */
export default function useURL() {
    const [url, setUrl] = useState(window.location);
    const searchParams = useMemo(
        () => Object.fromEntries(new URLSearchParams(url.search).entries()),
        [url],
    );

    const refreshURL = useCallback(() => {
        setUrl(window.location);
    }, []);
    const hasAllParams = useCallback(
        (...params) => {
            if (searchParams.length < params.length) return false;
            return params.every((param) => searchParams[param]);
        },
        [searchParams],
    );

    return { url, searchParams, refreshURL, hasAllParams };
}
