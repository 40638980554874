import { EventEmitter } from "events";

export const META_NAME_CONFIGURATION = "configuration-data";
export const INSTANCE_509 = "509";
export const INSTANCE_526 = "526";
export const INSTANCE_AUTHOR = "author";
export const PUBLIC_INSTANCES = [INSTANCE_509, INSTANCE_526];

export const STORE_KEY_USERNAME = "username";
export const STORE_KEY_PASSKEY_CHOICE = "passkey_choices";

export const CHOICES_VALUES = {
    ITSME: "itsme",
    PASSKEY: "biometric",
    PASSKEY_SETUP_TRUE: "true",
    PASSKEY_SETUP_FALSE: "false",
    PASSWORD_BACK: "back",
    PASSWORD_LOGIN: "login",
};

export const REPLACE_KEYS = {
    NUMBER: "$number",
    INSTANCE: "$instance",
    GO_TO_URL: "$redirectUrl",
};

export const VALIDATION_ACTIONS = {
    ADD: "add",
    REMOVE: "remove",
};

export const VALIDATION_KEYS = {
    NAME_REQUIRED: "nameRequired",
    PASSWORD_REQUIRED: "passwordRequired",
};

// Languages constants
export const LANGUAGE_CODE_ENGLISH = "en";
export const LANGUAGE_CODE_FRENCH = "fr";
export const LANGUAGE_CODE_DUTCH = "nl";

export const LANGUAGE_CODES = [
    LANGUAGE_CODE_ENGLISH,
    LANGUAGE_CODE_FRENCH,
    LANGUAGE_CODE_DUTCH,
];

export const LANGUAGES = [
    {
        label: "English",
        code: LANGUAGE_CODE_ENGLISH,
    },
    {
        label: "Français",
        code: LANGUAGE_CODE_FRENCH,
    },
    {
        label: "Nederlands",
        code: LANGUAGE_CODE_DUTCH,
    },
];

export const events = new EventEmitter();
