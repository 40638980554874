import { useMemo, useState } from "react";
import Button from "../../components/atoms/button/button";
import Link from "../../components/atoms/link/link";
import Spacer from "../../components/atoms/spacer/spacer";
import Title from "../../components/atoms/title/title";
import Form from "../../components/molecules/form/form";
import { buildClassName } from "../../helpers/className";
import {
    STORE_KEY_USERNAME,
    INSTANCE_526,
    VALIDATION_KEYS,
} from "../../helpers/const";
import { useAppActions, useAppState } from "../../hooks/useApp";
import { useContent } from "../../hooks/useContent";
import { useForm } from "../../hooks/useForm";
import { CallbackType, setStepInputValue } from "../../services/forgeRock";
import useErrors from "../../hooks/useErrors";
import Alert from "../../components/molecules/alert/alert";

export default function LoginUsername() {
    const {
        usernameInputLabel,
        usernameInputPlaceholder,
        forgottenNameUrl,
        forgottenNameLabel,
        usernameSubmitButtonLabel,
        errorMessageRequired,
        title,
        connectionTitle,
    } = useContent();

    const { currentStep, currentInstance, currentStepError } = useAppState();
    const { submitCurrentStep } = useAppActions();
    const { getErrorLabel } = useErrors();
    const { getValue, setValue, getInvalid, validate, validateAll } = useForm({
        fields: [
            {
                name: "username",
                initialValue: localStorage.getItem(STORE_KEY_USERNAME) || "",
                validation: (value) =>
                    !value && [VALIDATION_KEYS.NAME_REQUIRED],
            },
        ],
    });

    const [usernameSubmitLoading, setUsernameSubmitLoading] = useState(false);

    const errorLabel = useMemo(() => {
        if (!currentStepError) return null;
        if (!currentStepError.retry_left)
            return (
                getErrorLabel(currentStepError.error_message) ||
                currentStepError.error_message
            );

        const increment =
            currentStepError.retry_left > 2 ? "n" : currentStepError.retry_left;
        return (
            getErrorLabel(
                currentStepError.error_message,
                {
                    number: currentStepError.retry_left,
                },
                increment,
            ) || currentStepError.error_message
        );
    }, [currentStepError, getErrorLabel]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateAll(true);
        if (!isValid) return;
        setUsernameSubmitLoading(true);
        const value = getValue("username");
        localStorage.setItem(STORE_KEY_USERNAME, value);
        setStepInputValue(currentStep, CallbackType.NameCallback, value);
        submitCurrentStep(currentStep);
    };

    const is526 = currentInstance === INSTANCE_526;

    return (
        <>
            <div
                style={{
                    width: "100%",
                    maxWidth: is526 ? "253px" : "auto",
                }}
            >
                <Title weight={is526 ? "light" : "bold"} secondaryFont={is526}>
                    {title}
                </Title>
                {connectionTitle ? (
                    <>
                        <Spacer size=".5rem" />
                        <Title
                            variant="h2"
                            size="medium"
                            color="secondary"
                            weight="normal"
                        >
                            {connectionTitle}
                        </Title>
                    </>
                ) : null}
            </div>
            <Spacer size={is526 ? "2.75rem" : "1.5rem"} />
            {errorLabel ? (
                <>
                    <Alert variant="danger" innerHtml={errorLabel} />
                    <Spacer />
                </>
            ) : null}
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label
                        htmlFor="username"
                        helper={
                            forgottenNameUrl && forgottenNameLabel ? (
                                <Link
                                    href={forgottenNameUrl}
                                    size="small"
                                    truncate
                                >
                                    {forgottenNameLabel}
                                </Link>
                            ) : null
                        }
                    >
                        {usernameInputLabel}
                    </Form.Label>
                    {getInvalid("username") ? (
                        <Form.Error content={errorMessageRequired} />
                    ) : null}
                    <Form.Control
                        id="username"
                        className={buildClassName(
                            getInvalid("username") && "invalid",
                        )}
                        type="text"
                        placeholder={usernameInputPlaceholder}
                        value={getValue("username")}
                        onChange={(e) => setValue("username")(e.target.value)}
                        onBlur={() => validate("username", true)}
                    />
                </Form.Group>
                <Button
                    type="submit"
                    fullSize={is526}
                    loading={usernameSubmitLoading}
                >
                    {usernameSubmitButtonLabel}
                </Button>
            </Form>
        </>
    );
}
