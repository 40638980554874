import { useCallback, useContext } from "react";

import { contextContent } from "../context/content";
import { ERROR_LABEL_KEYS } from "../helpers/errors";

/**
 * @typedef {object} useErrorsReturns
 * @property {function(string): string | null} getErrorLabel
 */

/**
 * Hook to handle error messages
 * @return {useErrorsReturns}
 */
export default function useErrors() {
    const { labels } = useContext(contextContent);

    const getErrorLabel = useCallback(
        (errorKey, data, iteration) => {
            if (!errorKey) return null;
            const errorKeyAndIteration =
                errorKey + (iteration ? `_${iteration}` : "");
            const errorLabelKey = ERROR_LABEL_KEYS[errorKeyAndIteration];
            if (!errorLabelKey || !labels[errorLabelKey]) return null;
            if (!data) return labels[errorLabelKey];
            return Object.keys(data).reduce(
                (acc, key) => acc.replace(`$\{${key}}`, data[key]),
                labels[errorLabelKey],
            );
        },
        [labels],
    );

    return { getErrorLabel };
}
