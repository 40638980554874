import { useEffect, useMemo, useState } from "react";
import { useContent } from "../../../hooks/useContent";
import Alert from "../alert/alert";
import "./notifications.scss";
import { useAppState } from "../../../hooks/useApp";
import { INSTANCE_526 } from "../../../helpers/const";

const notificationTypeToAlertVariant509 = {
    0: "info",
    1: "info",
    2: "warning",
};

const notificationTypeToAlertVariant526 = {
    0: "success",
    1: "info",
    2: "warning",
};

export default function Notifications() {
    const { notifications } = useContent();
    const { currentInstance } = useAppState();

    const [list, setList] = useState(notifications);
    const notificationVariantMapper = useMemo(
        () =>
            currentInstance === INSTANCE_526
                ? notificationTypeToAlertVariant526
                : notificationTypeToAlertVariant509,
        [currentInstance],
    );

    useEffect(() => {
        setList(notifications);
    }, [notifications]);

    return list.length ? (
        <div className="notifications">
            {list.map(({ message, type }, index) => (
                <Alert
                    key={message}
                    className="notification"
                    innerHtml={message}
                    variant={notificationVariantMapper[type]}
                    onDismiss={() => {
                        setList((prevList) =>
                            prevList.filter((_, i) => i !== index),
                        );
                    }}
                />
            ))}
        </div>
    ) : null;
}
