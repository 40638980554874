import PropTypes from "prop-types";

import "./buttonZone.scss";
import { buildClassName } from "../../../helpers/className";

export default function ButtonZone({ children, column = false }) {
    return (
        <div className={buildClassName("button-zone", column && "column")}>
            {children}
        </div>
    );
}

ButtonZone.propTypes = {
    children: PropTypes.node.isRequired,
    column: PropTypes.bool,
};
