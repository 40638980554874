import PropTypes from "prop-types";

import "./separator.scss";

export default function Separator({ children }) {
    return (
        <div className="separator">{children ? <p>{children}</p> : null}</div>
    );
}

Separator.propTypes = {
    children: PropTypes.node,
};
