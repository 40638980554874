import { Router, Stage } from "./components/router/router";
import { RouterDebug } from "./components/debug/routerDebug";
import { CallbackType, getStepChoiceIndex, STAGE } from "./services/forgeRock";

import Notifications from "./components/molecules/notifications/notifications";

import { CHOICES_VALUES } from "./helpers/const";
import { useAppActions, useAppState } from "./hooks/useApp";

import ErrorPage from "./pages/error/error";
import LoginPage from "./pages/login/login";
import LoadingPage from "./pages/loading/loading";
import PasskeyPage from "./pages/passkey/passkey";
import PasswordPage from "./pages/password/password";
import SuccessPage from "./pages/login/success";
import Error404Page from "./pages/error/404";

export default function App() {
    const { currentStep } = useAppState();
    const { submitCurrentStep } = useAppActions();

    return (
        <>
            <Notifications />
            <div className="router-wrapper">
                <Router
                    onBack={({ currentStage, exitPage }) => {
                        // We just get out of SSO and go back to the previous page
                        if (
                            [
                                STAGE.Init,
                                STAGE.LoginCollectCredentials,
                                STAGE.CsamRedirect,
                                STAGE.PasskeyLogin,
                            ].includes(currentStage)
                        ) {
                            exitPage();
                            return;
                        }

                        // We are in the password page, we need to go back to the login page
                        if (
                            currentStage ===
                            STAGE.LoginCollectCredentialsPassword
                        ) {
                            const choiceIndex = getStepChoiceIndex(
                                currentStep,
                                CHOICES_VALUES.PASSWORD_BACK,
                            );

                            submitCurrentStep(currentStep, [
                                {
                                    type: CallbackType.ChoiceCallback,
                                    value: choiceIndex,
                                },
                            ]);
                            return;
                        }

                        // We just reload the page to go back to the start of the SSO flow
                        window.location.reload();
                    }}
                >
                    <Stage stage={STAGE.Init} component={LoadingPage} />
                    <Stage stage={STAGE.Error404} component={Error404Page} />
                    <Stage
                        stage={[
                            STAGE.LoginCollectCredentials,
                            STAGE.CsamRedirect,
                            STAGE.PasskeyLogin,
                        ]}
                        component={LoginPage}
                    />
                    <Stage
                        stage={STAGE.LoginCollectCredentialsPassword}
                        component={PasswordPage}
                    />
                    <Stage
                        stage={[
                            STAGE.PasskeyRegistrationChoice,
                            STAGE.PasskeyRegistration,
                        ]}
                        component={PasskeyPage}
                    />
                    <Stage stage={STAGE.LoginSuccess} component={SuccessPage} />
                    <Stage
                        stage={[
                            STAGE.Undefined,
                            STAGE.ServiceError,
                            STAGE.LoginFailure,
                        ]}
                        component={ErrorPage}
                    />
                </Router>
                <RouterDebug />
            </div>
        </>
    );
}
