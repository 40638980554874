import React from "react";
import PropTypes from "prop-types";
import "./card.scss";

import CardHeader from "./cardHeader";
import CardBody from "./cardBody";
import CardFooter from "./cardFooter";
import { buildClassName } from "../../../helpers/className";

export default function Card({ id = "", className = "", children }) {
    return (
        <div id={id} className={buildClassName("card", className)}>
            {children}
        </div>
    );
}

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

Card.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
