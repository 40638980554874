import * as React from "react";
import PropTypes from "prop-types";
import { buildClassName } from "../../../helpers/className";

export default function CardFooter({ className = "", children }) {
    return (
        <div className={buildClassName("card-footer", className)}>
            {children}
        </div>
    );
}

CardFooter.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
