import * as React from "react";
import PropTypes from "prop-types";
import "./form.scss";

import FormLabel from "./formLabel";
import FormControl from "./formControl";
import FormGroup from "./formGroup";
import FormError from "./formError";

export default function Form({
    className = "",
    method = null,
    action = null,
    onSubmit = () => {},
    children,
}) {
    const handleSubmit = (event) => {
        if (!method) {
            event.preventDefault();
        }
        onSubmit(event);
    };

    return (
        <form
            className={className}
            method={method}
            action={action}
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            {children}
        </form>
    );
}

Form.Label = FormLabel;
Form.Control = FormControl;
Form.Group = FormGroup;
Form.Error = FormError;

Form.propTypes = {
    className: PropTypes.string,
    action: PropTypes.string,
    method: PropTypes.string,
    onSubmit: PropTypes.func,
    children: PropTypes.node.isRequired,
};
