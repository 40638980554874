import * as React from "react";
import PropTypes from "prop-types";
import { buildClassName } from "../../../helpers/className";

export default function FormGroup({ className = "", children }) {
    return (
        <fieldset className={buildClassName("form-group", className)}>
            {children}
        </fieldset>
    );
}

FormGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
