import { createElement, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useAppState } from "../../hooks/useApp";
import { STAGE } from "../../services/forgeRock";

/**
 * @typedef {import('react').ReactElement} ReactElement
 */

let stageHistory = [];

/**
 * Router component
 * @param {object} props
 * @param {ReactElement | ReactElement[]} props.children
 * @param {STAGE} props.forcedStage
 * @param {(opts: { currentStage: STAGE, previousStage: STAGE, exitPage: () => void }) => void} props.onBack
 * @returns {ReactElement}
 */
export function Router({ children, onBack = () => {} }) {
    const { currentStage, debugCurrentStage } = useAppState();

    useEffect(() => {
        stageHistory = [];
        if (window.history.state !== null) return;
        window.history.pushState(stageHistory, "");
    }, []);

    useEffect(() => {
        function handlePopState(e) {
            e.preventDefault();
            if (stageHistory.length > 1) {
                const cs = stageHistory.pop();
                const ps = stageHistory[stageHistory.length - 1];
                onBack({
                    currentStage: cs,
                    previousStage: ps,
                    exitPage: () => window.history.back(),
                });
                window.history.pushState(stageHistory, "");
            }
        }

        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, [onBack]);

    const stageToShow = useMemo(() => {
        if (!children || !children.length) {
            return children;
        }

        return (
            children.find((child) =>
                isStage(child.props.stage, debugCurrentStage || currentStage),
            ) ||
            children.find((child) =>
                isStage(child.props.stage, STAGE.Undefined),
            )
        );
    }, [children, currentStage, debugCurrentStage]);

    useEffect(() => {
        stageHistory.push(currentStage);
        window.history.replaceState(stageHistory, "");
    }, [stageToShow, currentStage]);

    return stageToShow || null;
}

Router.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
    onBack: PropTypes.func,
};

/**
 * Stage component
 * @param {object} props
 * @param {string} props.stage
 * @param {ReactElement} props.component
 * @param {object} props.props
 */
export function Stage({ component, props }) {
    return createElement(component, props);
}

Stage.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    stage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    component: PropTypes.func.isRequired,
    props: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

function isStage(stageStringOrArray, stage) {
    if (typeof stageStringOrArray === "string") {
        return stageStringOrArray === stage;
    }

    return stageStringOrArray.includes(stage);
}
