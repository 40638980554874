// eslint-disable-next-line import/prefer-default-export
export const ERROR_LABEL_KEYS = {
    mca_security_data_credentials_exception: "errorMessageCredentialsException",
    mca_security_data_credentials_not_found: "errorMessageCredentialsNotFound",
    mca_security_data_credentials_norefid: "errorMessageCredentialsNoRefId",
    mca_security_data_credentials_locked: "errorMessageCredentialsLocked",
    mca_security_data_credentials_inactive: "errorMessageCredentialsInactive",

    csam_invalid_response: "errorMessageCSAMInvalidResponse",
    csam_no_local_account: "errorMessageCSAMNoLocalAccount",

    webauthn_cancelled: "errorMessagePasskeyCancelled",

    invalid_login: "errorMessageInvalidLogin",
    invalid_login_n: "errorMessageTriesLeft",
    invalid_login_2: "errorMessageTriesLeft2",
    invalid_login_1: "errorMessageTriesLeft1",

    account_locked: "errorMessageCredentialsLocked",

    login_failure: "errorMessageLoginFailure",
    biometric_authentication_failed:
        "errorMessageBiometricAuthenticationFailed",
    biometric_registration_failed: "errorMessageBiometricRegistrationFailed",
};
