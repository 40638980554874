import { useMemo, useState } from "react";
import Button from "../../components/atoms/button/button";
import Link from "../../components/atoms/link/link";
import Spacer from "../../components/atoms/spacer/spacer";
import Title from "../../components/atoms/title/title";
import Form from "../../components/molecules/form/form";
import Page, { PageBody } from "../../components/molecules/page/page";

import { buildClassName } from "../../helpers/className";
import {
    CHOICES_VALUES,
    INSTANCE_526,
    STORE_KEY_USERNAME,
    VALIDATION_KEYS,
} from "../../helpers/const";

import { useAppActions, useAppState } from "../../hooks/useApp";
import { useContent } from "../../hooks/useContent";
import { useForm } from "../../hooks/useForm";

import {
    CallbackType,
    getStepChoiceIndex,
    setStepInputValue,
} from "../../services/forgeRock";
import useErrors from "../../hooks/useErrors";
import Alert from "../../components/molecules/alert/alert";
import Text from "../../components/atoms/text/text";
import Icon from "../../components/atoms/icon/icon";
import ButtonZone from "../../components/atoms/buttonZone/buttonZone";

export default function PasswordPage() {
    const {
        forgottenPasswordLabel,
        forgottenPasswordUrl,
        passwordInputLabel,
        passwordInputPlaceholder,
        passwordSubmitButtonLabel,
        backButtonLabel,
        errorMessageRequired,
        title,
        connectionTitle,
        passwordUsernameInfo,
    } = useContent();

    const { currentInstance, currentStep, currentStepError } = useAppState();
    const { submitCurrentStep } = useAppActions();
    const { getErrorLabel } = useErrors();
    const { getValue, setValue, getInvalid, validate, validateAll } = useForm({
        fields: [
            {
                name: "password",
                initialValue: "",
                validation: (value) =>
                    !value && [VALIDATION_KEYS.PASSWORD_REQUIRED],
            },
        ],
    });

    const [showPassword, setShowPassword] = useState(false);
    const [passwordSubmitLoading, setPasswordSubmitLoading] = useState(false);

    const handleShowPassword = () => setShowPassword(!showPassword);

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateAll(true);
        if (!isValid) return;
        setPasswordSubmitLoading(true);
        setStepInputValue(
            currentStep,
            CallbackType.PasswordCallback,
            getValue("password"),
        );
        submitCurrentStep(currentStep);
    };

    const handleBack = (e) => {
        e.preventDefault();
        const choiceIndex = getStepChoiceIndex(
            currentStep,
            CHOICES_VALUES.PASSWORD_BACK,
        );
        submitCurrentStep(currentStep, [
            { type: CallbackType.ChoiceCallback, value: choiceIndex },
        ]);
    };

    const errorLabel = useMemo(() => {
        if (!currentStepError) return null;
        if (!currentStepError.retry_left)
            return (
                getErrorLabel(currentStepError.error_message) ||
                currentStepError.error_message
            );

        const increment =
            currentStepError.retry_left > 2
                ? null
                : currentStepError.retry_left;

        return (
            getErrorLabel(
                currentStepError.error_message,
                {
                    number: currentStepError.retry_left,
                },
                increment,
            ) || currentStepError.error_message
        );
    }, [currentStepError, getErrorLabel]);

    const is526 = currentInstance === INSTANCE_526;

    return (
        <Page>
            <PageBody>
                <Spacer size={is526 ? "3rem" : "1.5rem"} />
                <div
                    style={{
                        width: "100%",
                        maxWidth: is526 ? "253px" : "auto",
                        alignSelf: "center",
                    }}
                >
                    <Title
                        weight={is526 ? "light" : "bold"}
                        secondaryFont={is526}
                    >
                        {title}
                    </Title>
                    {connectionTitle ? (
                        <>
                            <Spacer size=".5rem" />
                            <Title
                                variant="h2"
                                size="medium"
                                color="secondary"
                                weight="normal"
                            >
                                {connectionTitle}
                            </Title>
                        </>
                    ) : null}
                </div>
                <Spacer size={is526 ? "2rem" : "1rem"} />
                {errorLabel && errorLabel !== "webauthn_cancelled" ? (
                    <>
                        <Alert variant="danger" innerHtml={errorLabel} />
                        <Spacer />
                    </>
                ) : null}
                <Text secondaryFont={!is526} color="secondary" weight="light">
                    {passwordUsernameInfo}{" "}
                    <Text
                        variant="span"
                        secondaryFont={!is526}
                        color="primary"
                        weight="bold"
                    >
                        {localStorage.getItem(STORE_KEY_USERNAME)}
                    </Text>
                </Text>
                <Spacer size={is526 ? "2.75rem" : "1.5rem"} />
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label
                            htmlFor="password"
                            helper={
                                forgottenPasswordUrl &&
                                forgottenPasswordLabel ? (
                                    <Link
                                        href={forgottenPasswordUrl}
                                        size="small"
                                        truncate
                                    >
                                        {forgottenPasswordLabel}
                                    </Link>
                                ) : null
                            }
                        >
                            {passwordInputLabel}
                        </Form.Label>
                        {getInvalid("password") ? (
                            <Form.Error content={errorMessageRequired} />
                        ) : null}
                        <Form.Control
                            id="password"
                            className={buildClassName(
                                getInvalid("password") && "invalid",
                            )}
                            type={showPassword ? "text" : "password"}
                            value={getValue("password")}
                            onChange={(e) =>
                                setValue("password")(e.target.value)
                            }
                            placeholder={passwordInputPlaceholder}
                            onBlur={() => validate("password", true)}
                            extraElement={
                                <Button
                                    variant="icon"
                                    type="button"
                                    title="Show password"
                                    onClick={handleShowPassword}
                                >
                                    <Icon
                                        variant={
                                            showPassword
                                                ? "eye-close"
                                                : "eye-open"
                                        }
                                        color="input-border"
                                    />
                                </Button>
                            }
                        />
                    </Form.Group>
                    <ButtonZone column={is526}>
                        <Button
                            type="submit"
                            fullSize={is526}
                            loading={passwordSubmitLoading}
                        >
                            {passwordSubmitButtonLabel}
                        </Button>
                        <Button
                            type="button"
                            variant="text"
                            fullSize={is526}
                            onClick={handleBack}
                        >
                            {backButtonLabel}
                        </Button>
                    </ButtonZone>
                </Form>
                <Spacer size={is526 ? "3rem" : "1.5rem"} />
            </PageBody>
        </Page>
    );
}
