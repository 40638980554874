import Spacer from "../../components/atoms/spacer/spacer";
import Page, { PageBody } from "../../components/molecules/page/page";
import Pending from "../../components/molecules/pending/pending";

export default function LoadingPage() {
    return (
        <Page>
            <PageBody center>
                <Spacer size="2rem" />
                <Pending />
                <Spacer size="2rem" />
            </PageBody>
        </Page>
    );
}
