import React from "react";
import PropTypes from "prop-types";

import { useAppActions, useAppState } from "../../../hooks/useApp";
import Select from "../../atoms/select/select";

export default function LanguageSelector({ short = false }) {
    const { currentLanguage, languages } = useAppState();
    const { updateLanguage } = useAppActions();

    const handleUpdateLang = (lang) => {
        updateLanguage(lang, true);
    };

    return (
        <Select
            selected={currentLanguage}
            onChange={handleUpdateLang}
            options={languages}
            displayKey={short ? "code" : "label"}
            valueKey="code"
        />
    );
}

LanguageSelector.propTypes = {
    short: PropTypes.bool,
};
