import * as React from "react";
import PropTypes from "prop-types";
import { buildClassName } from "../../../helpers/className";

export default function FormControl({
    className = "",
    type = "",
    id = "",
    rows = "",
    value = "",
    placeholder = "",
    onChange = null,
    onKeyDown = null,
    onBlur = null,
    extraElement = null,
}) {
    const handleChange = (oEvent) => onChange && onChange(oEvent);
    const handleKeyDown = (oEvent) => onKeyDown && onKeyDown(oEvent);
    const handleBlur = (oEvent) => onBlur && onBlur(oEvent);
    return (
        <div className={buildClassName("form-control", className)}>
            {type === "textarea" ? (
                <textarea
                    id={id}
                    name={id}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                />
            ) : (
                <input
                    id={id}
                    name={id}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                />
            )}
            <div className="extra">{extraElement}</div>
        </div>
    );
}

FormControl.propTypes = {
    id: PropTypes.string,
    rows: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    extraElement: PropTypes.node,
};
