import React from "react";
import PropTypes from "prop-types";

import "./pending.scss";
import { buildClassName } from "../../../helpers/className";

export default function Pending({
    button = false,
    small = false,
    inverted = false,
}) {
    return (
        <div className={buildClassName("pending", button && "pending-button")}>
            <svg
                className={buildClassName(
                    "icon",
                    "no-fill",
                    `icon-size-${small ? 2 : 3}`,
                    inverted && "inverted",
                )}
                width="40"
                height="40"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20 1.66667C30.1253 1.66667 38.3334 9.87478 38.3334 20C38.3334 30.1252 30.1253 38.3333 20 38.3333C9.87481 38.3333 1.6667 30.1252 1.6667 20C1.6667 9.87478 9.87481 1.66667 20 1.66667Z"
                    className="circle-path"
                    strokeOpacity="0.25"
                    strokeWidth="3.33333"
                />
                <path
                    d="M20 1.66665C9.87478 1.66665 1.66667 9.87476 1.66667 20C1.66667 30.1252 9.87478 38.3333 20 38.3333"
                    stroke="url(#paint_spinner)"
                    strokeWidth="3.33333"
                    strokeLinecap="round"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 20 20"
                        to="360 20 20"
                        dur=".5s"
                        repeatCount="indefinite"
                    />
                </path>
                <defs>
                    <linearGradient
                        className="gradient"
                        id="paint_spinner"
                        x1="20"
                        y1="1.66665"
                        x2="20"
                        y2="36.6666"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop />
                        <stop offset="0.583333" />
                        <stop offset="1" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

Pending.propTypes = {
    small: PropTypes.bool,
    inverted: PropTypes.bool,
    button: PropTypes.bool,
};
