import Spacer from "../../components/atoms/spacer/spacer";
import Text from "../../components/atoms/text/text";
import Title from "../../components/atoms/title/title";
import Page, { PageBody } from "../../components/molecules/page/page";
import { INSTANCE_526 } from "../../helpers/const";
import { useAppState } from "../../hooks/useApp";
import { useContent } from "../../hooks/useContent";

export default function Error404Page() {
    const { currentInstance } = useAppState();

    const { error404Content, error404LogoUrl, error404Title } = useContent();

    const is526 = currentInstance === INSTANCE_526;
    return (
        <Page noActionsInHeader>
            <PageBody>
                <Spacer size="2rem" />
                <img
                    src={error404LogoUrl}
                    alt="404"
                    style={{ width: "7.5rem", height: "7.5rem" }}
                />
                <Spacer size={is526 ? ".5rem" : "1.5rem"} />
                <div
                    style={{
                        width: "100%",
                        maxWidth: is526 ? "253px" : "auto",
                    }}
                >
                    <Title size="large">{error404Title}</Title>
                </div>
                <Spacer size={is526 ? "2rem" : "1rem"} />
                <Text
                    weight="light"
                    secondaryFont={!is526}
                    innerHtml={error404Content}
                />
                <Spacer size="2rem" />
            </PageBody>
        </Page>
    );
}
