import { sha256 } from "js-sha256";
import { STORE_KEY_PASSKEY_CHOICE } from "../helpers/const";

/**
 *
 * @param {string} user
 * @param {boolean} choice
 */
export function addPasskeyChoice(user) {
    const userHashed = sha256.create().update(user).hex();
    const passkeyChoices = getPasskeyChoices();
    if (passkeyChoices.find((choice) => choice === userHashed)) return;

    passkeyChoices.push(userHashed);
    localStorage.setItem(
        STORE_KEY_PASSKEY_CHOICE,
        JSON.stringify(passkeyChoices),
    );
}

export function hasPasskeyChoice(user) {
    const userHashed = sha256.create().update(user).hex();
    return getPasskeyChoices().find((choice) => choice === userHashed);
}

export function getPasskeyChoices() {
    return JSON.parse(localStorage.getItem(STORE_KEY_PASSKEY_CHOICE) || "[]");
}
