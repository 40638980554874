import React from "react";
import { createRoot } from "react-dom/client";

import App from "./app";
import { AppProvider } from "./context/app";

import "./scss/common/main.scss";
import { EnvironmentProvider, environmentContext } from "./context/environment";
import { ContentProvider } from "./context/content";

createRoot(document.getElementById("root")).render(
    <EnvironmentProvider>
        <environmentContext.Consumer>
            {(env) => (
                <div className={`app theme-${env.instance}`}>
                    <ContentProvider environment={env}>
                        <AppProvider environment={env}>
                            <App />
                        </AppProvider>
                    </ContentProvider>
                </div>
            )}
        </environmentContext.Consumer>
    </EnvironmentProvider>,
);
