import { useMemo } from "react";
import Spacer from "../../components/atoms/spacer/spacer";
import Alert from "../../components/molecules/alert/alert";
import Page, { PageBody } from "../../components/molecules/page/page";
import { useAppState } from "../../hooks/useApp";
import useErrors from "../../hooks/useErrors";

export default function ErrorPage() {
    const { currentStepError, serviceError } = useAppState();
    const { getErrorLabel } = useErrors();

    const errorLabel = useMemo(() => {
        if (!currentStepError) return null;
        return getErrorLabel(currentStepError.error_message);
    }, [currentStepError, getErrorLabel]);

    return (
        <Page>
            <PageBody>
                <Spacer />
                <Alert
                    variant="danger"
                    innerHtml={
                        errorLabel ||
                        currentStepError?.error_details ||
                        serviceError?.stack ||
                        "An error occurred"
                    }
                    title={
                        errorLabel
                            ? ""
                            : currentStepError?.error_message ||
                              serviceError?.message
                    }
                    breakWords
                />
                <Spacer />
            </PageBody>
        </Page>
    );
}
