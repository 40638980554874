import * as React from "react";
import PropTypes from "prop-types";

import Icon from "../../atoms/icon/icon";

export default function FormError({ content = "Error" }) {
    return (
        <div className="invalid-feedback">
            <Icon variant="danger" size={1} />
            <div
                className="content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
}

FormError.propTypes = {
    content: PropTypes.string,
};
