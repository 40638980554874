/* eslint-disable no-template-curly-in-string */
import { useMemo } from "react";
import Title from "../../components/atoms/title/title";
import Page, { PageBody } from "../../components/molecules/page/page";
import Alert from "../../components/molecules/alert/alert";
import Spacer from "../../components/atoms/spacer/spacer";

import { INSTANCE_526 } from "../../helpers/const";
import { useContent } from "../../hooks/useContent";
import { useAppState } from "../../hooks/useApp";
import useURL from "../../hooks/useURL";

export default function SuccessPage() {
    const { searchParams } = useURL();
    const { currentInstance } = useAppState();
    const {
        successLogoUrl,
        successLogoAlt,
        successTitle,
        successSubtitle,
        successAlertContent,
    } = useContent();

    const successAlertContentWithUrl = useMemo(() => {
        if (!successAlertContent) return "";
        if (!searchParams.success)
            return successAlertContent.replace("${url}", "[error]");
        return successAlertContent.replace(
            "${url}",
            `<a href="${searchParams.success}" style="word-break: break-all;">${searchParams.success}</a>`,
        );
    }, [successAlertContent, searchParams.success]);

    const is526 = currentInstance === INSTANCE_526;

    return (
        <Page>
            <PageBody center>
                <Spacer size={is526 ? "3rem" : "2rem"} />
                {successLogoUrl && (
                    <>
                        <img
                            src={successLogoUrl}
                            width="140px"
                            alt={successLogoAlt}
                        />
                        <Spacer size={is526 ? "2rem" : "1rem"} />
                    </>
                )}
                <div style={{ maxWidth: is526 ? "384px" : "inherit" }}>
                    <Title
                        weight="normal"
                        size={is526 ? "medium-large" : "medium"}
                        center
                    >
                        {successTitle}
                    </Title>
                    {is526 ? <Spacer size="1rem" /> : null}
                    <Title
                        variant="h2"
                        size="small"
                        color={is526 ? "primary" : "secondary"}
                        weight={is526 ? "light" : "bold"}
                        secondaryFont={!is526}
                        center
                    >
                        {successSubtitle}
                    </Title>
                </div>
                {successAlertContent ? (
                    <>
                        <Spacer size="2rem" />
                        <Alert
                            innerHtml={successAlertContentWithUrl}
                            variant="info"
                        />
                    </>
                ) : null}
                <Spacer size="2rem" />
            </PageBody>
        </Page>
    );
}
