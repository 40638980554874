import { useLayoutEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import "./select.scss";
import Icon from "../icon/icon";
import { buildClassName } from "../../../helpers/className";

/**
 * @typedef {import('react').ReactElement} ReactElement
 */

/**
 * Select component
 * @param {object} props
 * @param {string} props.selected
 * @param {function} props.onChange
 * @param {object[]} props.options
 * @param {string} props.displayKey
 * @param {string} props.valueKey
 * @returns {ReactElement}
 */
export default function Select({
    selected,
    onChange = () => {},
    options = [],
    displayKey = "label",
    valueKey = "value",
}) {
    const [showOptions, setShowOptions] = useState(false);

    const displayValue = useMemo(
        () =>
            options.find((option) => option[valueKey] === selected)?.[
                displayKey
            ],
        [selected, options, displayKey, valueKey],
    );

    const filteredOptions = useMemo(
        () => options.filter((option) => option[valueKey] !== selected),
        [options, selected, valueKey],
    );

    useLayoutEffect(() => {
        const handleOutsideClick = (e) => {
            if (!showOptions) return;
            if (e.target.closest(".select") === null) {
                setShowOptions(false);
            }
        };
        window.addEventListener("click", handleOutsideClick);

        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, [showOptions]);

    return (
        <div className={buildClassName("select", !showOptions && "collapsed")}>
            <button
                className="select-button"
                type="button"
                aria-label={displayValue}
                aria-roledescription="open dropdown"
                onClick={() => setShowOptions(!showOptions)}
            >
                <p>{displayValue}</p>
                <Icon variant="dropdown-arrow" />
            </button>
            <div className="select-dropdown">
                {filteredOptions.map((option) => (
                    <button
                        key={option[valueKey]}
                        type="button"
                        onClick={() => {
                            onChange(option[valueKey]);
                            setShowOptions(false);
                        }}
                        aria-label={option[displayKey]}
                        aria-roledescription="option"
                    >
                        {option[displayKey]}
                    </button>
                ))}
            </div>
        </div>
    );
}

Select.propTypes = {
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    displayKey: PropTypes.string,
    valueKey: PropTypes.string,
};
