import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { calculateDefaultLanguage, calculateInstance } from "../helpers/app";
import { LANGUAGES } from "../helpers/const";
import useURL from "../hooks/useURL";

/**
 * @typedef {Object} EnvironmentState
 * @property {string} publicUrl
 * @property {string} fesUrl
 * @property {string} instance
 * @property {string} locale
 * @property {Object} localeMap
 * @property {Object} magnolia
 * @property {string} magnolia.id
 * @property {string} magnolia.context
 * @property {string} magnolia.host
 * @property {string} magnolia.instance
 * @property {boolean} magnolia.editMode
 * @property {Object} magnolia.api
 * @property {string} magnolia.api.templates
 * @property {string} magnolia.api.assets
 * @property {string} error
 * @property {boolean} is404
 * @property {boolean} loading
 */

/**
 * @type {EnvironmentState}
 */
const initialContext = {
    publicUrl: "/%PUBLIC_URL_DEFAULT_BASE%/.resources/webresources",
    fesUrl: "https://fes$instance-dev.m-team.be/login",
    instance: "",
    locale: "",
    localeMap: {},
    languages: [],
    magnolia: {
        id: "",
        context: "",
        host: "https://partenamut.fes509-dev.m-team.be",
        instance: "author",
        editMode: false,
        api: {
            templates: "/.rest/template-annotations/v1",
            assets: "/dam",
        },
    },

    error: undefined,
    is404: false,
    loading: true,
};

/**
 * @type {React.Context<EnvironmentState>}
 */
export const environmentContext = createContext();

/**
 * @type {React.Context<(envAction: EnvironmentAction) => void>}
 */
export const environmentDispatchContext = createContext();

export function EnvironmentProvider({ children }) {
    const [context, dispatch] = useReducer(environmentReducer, initialContext);
    const { hasAllParams } = useURL();

    // Set the env data from the HTML meta tags
    useEffect(() => {
        if (
            !(
                hasAllParams("success") ||
                hasAllParams("goto", "realm", "service") ||
                hasAllParams("code", "iss", "scope", "state", "client_id")
            )
        ) {
            dispatch({ type: "SET_404" });
        }

        try {
            const metaData = document.querySelector(
                'meta[name="configuration-data"]',
            );
            const data = JSON.parse(metaData?.content);

            const editMode = document.querySelector('meta[name="edit-mode"]');
            const isEditMode = editMode ? JSON.parse(editMode.content) : false;

            dispatch({
                type: "SET_ENV",
                payload: {
                    fesUrl: data.fes,
                    localeMap: data.localeMap,
                    locale: data.locale,
                    magnolia: {
                        id: data.id,
                        context: data.context,
                        instance: data.instanceId,
                        editMode: isEditMode,
                    },
                    loading: false,
                },
            });
        } catch (err) {
            dispatch({
                type: "SET_ERROR",
                payload: { error: err.message, loading: false },
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <environmentContext.Provider value={context}>
            <environmentDispatchContext.Provider value={dispatch}>
                {children}
            </environmentDispatchContext.Provider>
        </environmentContext.Provider>
    );
}

EnvironmentProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

/**
 * @typedef {Object} EnvironmentAction
 * @property {('SET_ENV'|'UPDATE_LANGUAGE'|'SET_ERROR'|'SET_404')} type
 * @property {any} payload
 */

/**
 * @param {EnvironmentState} state
 * @param {EnvironmentAction} action
 * @returns {EnvironmentState}
 */
function environmentReducer(state, action) {
    switch (action.type) {
        case "SET_ENV": {
            const currentInstance = calculateInstance(
                action.payload.magnolia.instance,
            );

            const availableLanguages = Object.keys(action.payload.localeMap);
            const languages = LANGUAGES.filter((l) =>
                availableLanguages.includes(l.code),
            );

            return {
                ...state,
                ...action.payload,
                instance: currentInstance,
                locale:
                    action.payload.locale ||
                    calculateDefaultLanguage(currentInstance),
                languages,
            };
        }

        case "UPDATE_LANGUAGE": {
            if (action.payload.forceReload) {
                window.location.pathname =
                    state.localeMap[action.payload.language];
                return state;
            }

            document
                .querySelector("html")
                .setAttribute("lang", action.payload.language);

            return {
                ...state,
                locale: action.payload.language,
            };
        }

        case "SET_ERROR": {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        }

        case "SET_404": {
            return {
                ...state,
                is404: true,
            };
        }

        default: {
            // eslint-disable-next-line no-console
            console.warn(
                "Unknown action in Environment context",
                action.type,
                action.payload,
            );
            return state;
        }
    }
}
