/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useMemo } from "react";
import PropTypes from "prop-types";

import "./page.scss";

import { useContent } from "../../../hooks/useContent";
import { buildClassName } from "../../../helpers/className";

import Card from "../../atoms/card/card";
import LanguageSelector from "../../organisms/languageSelector/languageSelector";

const { Header: CardHeader, Body: CardBody, Footer: CardFooter } = Card;

export default function Page({ children, noActionsInHeader = false }) {
    const { brandLogoAlt, brandLogoUrl } = useContent();

    const body = useMemo(() => {
        // eslint-disable-next-line no-underscore-dangle
        if (!children.length && children.type?.name === PageBody.name) {
            return children;
        }

        if (!children.length) {
            throw new Error("Should have one PageBody");
        }

        const bodies = children.filter(
            // eslint-disable-next-line no-underscore-dangle
            (child) => child?.type?.name === PageBody.name,
        );

        if (bodies.length === 0) {
            throw new Error("Should have one PageBody");
        }

        if (bodies.length > 1) {
            throw new Error("Only one PageBody is allowed");
        }

        return bodies[0];
    }, [children]);

    const footer = useMemo(() => {
        if (!children.length) {
            return null;
        }

        const footers = children.filter(
            // eslint-disable-next-line no-underscore-dangle
            (child) => child?.type?.name === PageFooter.name,
        );

        if (footers.length === 0) {
            return null;
        }

        if (footers.length > 1) {
            throw new Error("Only one PageFooter is allowed");
        }

        return footers[0];
    }, [children]);

    const extra = useMemo(() => {
        if (!children.length) {
            return null;
        }

        const extras = children.filter(
            (child) =>
                child?.type?.name !== PageBody.name &&
                child?.type?.name !== PageFooter.name,
        );

        if (extras.length === 0) {
            return null;
        }

        return extras;
    }, [children]);

    return (
        <div className="sso-page">
            <div className="container">
                <Card id="main">
                    <CardHeader
                        logo={<img src={brandLogoUrl} alt={brandLogoAlt} />}
                    >
                        {!noActionsInHeader ? <LanguageSelector short /> : null}
                    </CardHeader>
                    <CardBody
                        className={buildClassName(
                            body.props.className,
                            body.props.center && "center",
                        )}
                    >
                        {body}
                    </CardBody>
                    {footer ? (
                        <CardFooter className={footer.props.className}>
                            {footer}
                        </CardFooter>
                    ) : null}
                </Card>
                {extra}
            </div>
        </div>
    );
}

Page.propTypes = {
    children: PropTypes.node.isRequired,
    noActionsInHeader: PropTypes.bool,
};

/**
 * Page Body component
 * @param {object} props - Props
 * @param {React.ReactNode} props.children - Children
 * @param {string} props.className - Class name
 * @param {boolean} props.center - Center
 * @returns {React.JSX.Element}
 */
export function PageBody({ children }) {
    return children;
}

PageBody.propTypes = {
    children: PropTypes.node.isRequired,
};

/**
 * Page Footer component
 * @param {object} props - Props
 * @param {React.ReactNode} props.children - Children
 * @param {string} props.className - Class name
 * @returns {React.JSX.Element}
 */
export function PageFooter({ children }) {
    return children;
}

PageFooter.propTypes = {
    children: PropTypes.node.isRequired,
};
