import * as React from "react";
import PropTypes from "prop-types";
import { buildClassName } from "../../../helpers/className";

export default function CardHeader({ logo = null, children }) {
    return (
        <div
            className={buildClassName(
                "card-header",
                (children || children?.length) && "has-actions",
            )}
        >
            {logo ? <div className="card-header-logo">{logo}</div> : null}
            {children}
        </div>
    );
}

CardHeader.propTypes = {
    logo: PropTypes.node,
    children: PropTypes.node,
};
