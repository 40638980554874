import React from "react";
import PropTypes from "prop-types";
import "./link.scss";
import { buildClassName } from "../../../helpers/className";

/**
 * Link component
 * @param {object} props
 * @param {string} props.href
 * @param {string} [props.title=""]
 * @param {"_self"|"_blank"} [props.target="_self"]
 * @param {"primary"|"secondary"} [props.variant="primary"]
 * @param {"small"|"normal"} [props.size="normal"]
 * @param {string} props.className
 * @param {ReactNode} props.children
 */
export default function Link({
    href,
    title = "",
    target = "_self",
    variant = "primary",
    size = "normal",
    className = "",
    truncate = false,
    children,
}) {
    return (
        <a
            className={buildClassName(
                `link link-${variant} size-${size}`,
                truncate && "truncate",
                className,
            )}
            target={target}
            title={title}
            href={href}
        >
            {children}
        </a>
    );
}

Link.propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    variant: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    target: PropTypes.string,
    truncate: PropTypes.bool,
};
