import * as React from "react";
import PropTypes from "prop-types";
import { buildClassName } from "../../../helpers/className";

export default function CardBody({ className = "", children }) {
    return (
        <div className={buildClassName("card-body", className)}>{children}</div>
    );
}

CardBody.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
