import React from "react";
import PropTypes from "prop-types";
import "./icon.scss";
import { buildClassName } from "../../../helpers/className";
import { useAppState } from "../../../hooks/useApp";
import { INSTANCE_526 } from "../../../helpers/const";

/**
 * Icon component
 * @param {object} props
 * @param {string} props.variant
 * @param {string} [props.className]
 * @param {string} [props.color]
 * @param {1|2|3} [props.size=2]
 */
export default function Icon({
    variant,
    className = "",
    color = "",
    size = 2,
}) {
    const { currentInstance } = useAppState();
    const fillColor = color ? `var(--theme-color-${color})` : null;

    const iconClassName = buildClassName(
        "icon",
        `icon-size-${size}`,
        className,
    );

    switch (variant) {
        case "close":
            return (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_11282_1920)">
                        <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" />
                    </g>
                    <defs>
                        <clipPath id="clip0_11282_1920">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "success":
            return currentInstance === INSTANCE_526 ? (
                <svg
                    className={iconClassName}
                    viewBox="0 0 32 32"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.6 21.2049L6.34657 13.9514L4.64952 15.6485L13.6 24.599L28.5505 9.6485L26.8534 7.95144L13.6 21.2049Z"
                    />
                </svg>
            ) : (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z" />
                </svg>
            );

        case "info":
            return currentInstance === INSTANCE_526 ? (
                <svg
                    className={iconClassName}
                    viewBox="0 0 32 32"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.6 11.2L18.4 11.2V6.4L13.6 6.4V11.2ZM13.6 25.6H18.4L18.4 12.8H13.6L13.6 25.6Z"
                    />
                </svg>
            ) : (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z" />
                </svg>
            );

        case "warning":
            return currentInstance === INSTANCE_526 ? (
                <svg
                    className={iconClassName}
                    viewBox="0 0 32 32"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.4 6.3999H13.6V19.1999H18.4V6.3999ZM18.4 20.7999H13.6V25.5999H18.4V20.7999Z"
                    />
                </svg>
            ) : (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4.47012 20.9999H19.5301C21.0701 20.9999 22.0301 19.3299 21.2601 17.9999L13.7301 4.98993C12.9601 3.65993 11.0401 3.65993 10.2701 4.98993L2.74012 17.9999C1.97012 19.3299 2.93012 20.9999 4.47012 20.9999ZM12.0001 13.9999C11.4501 13.9999 11.0001 13.5499 11.0001 12.9999V10.9999C11.0001 10.4499 11.4501 9.99993 12.0001 9.99993C12.5501 9.99993 13.0001 10.4499 13.0001 10.9999V12.9999C13.0001 13.5499 12.5501 13.9999 12.0001 13.9999ZM13.0001 17.9999H11.0001V15.9999H13.0001V17.9999Z" />
                </svg>
            );

        case "error":
        case "danger":
            return currentInstance === INSTANCE_526 ? (
                <svg
                    className={iconClassName}
                    viewBox="0 0 32 32"
                    style={{
                        fill: fillColor || "var(--theme-color-danger)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.4 6.3999H13.6V19.1999H18.4V6.3999ZM18.4 20.7999H13.6V25.5999H18.4V20.7999Z"
                    />
                </svg>
            ) : (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{
                        fill: fillColor || "var(--theme-color-danger)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7471 9.41503 20.7189 6.93678 18.8911 5.10893C17.0632 3.28108 14.585 2.25292 12 2.25V2.25ZM11.25 7.5C11.25 7.30109 11.329 7.11032 11.4697 6.96967C11.6103 6.82902 11.8011 6.75 12 6.75C12.1989 6.75 12.3897 6.82902 12.5303 6.96967C12.671 7.11032 12.75 7.30109 12.75 7.5V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75V7.5ZM12 17.25C11.7775 17.25 11.56 17.184 11.375 17.0604C11.19 16.9368 11.0458 16.7611 10.9606 16.5555C10.8755 16.35 10.8532 16.1238 10.8966 15.9055C10.94 15.6873 11.0472 15.4868 11.2045 15.3295C11.3618 15.1722 11.5623 15.065 11.7805 15.0216C11.9988 14.9782 12.225 15.0005 12.4305 15.0856C12.6361 15.1708 12.8118 15.315 12.9354 15.5C13.059 15.685 13.125 15.9025 13.125 16.125C13.125 16.4234 13.0065 16.7095 12.7955 16.9205C12.5845 17.1315 12.2984 17.25 12 17.25Z" />
                </svg>
            );

        case "eye-open":
            return (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 5C7 5 2.73 8.11 1 12.5C2.73 16.89 7 20 12 20C17 20 21.27 16.89 23 12.5C21.27 8.11 17 5 12 5ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5ZM12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z" />
                </svg>
            );

        case "eye-close":
            return (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 7.49999C14.76 7.49999 17 9.73999 17 12.5C17 13.01 16.9 13.5 16.76 13.96L19.82 17.02C21.21 15.79 22.31 14.25 23 12.49C21.27 8.10999 17 4.99999 12 4.99999C10.73 4.99999 9.51 5.19999 8.36 5.56999L10.53 7.73999C11 7.59999 11.49 7.49999 12 7.49999ZM2.71 4.15999C2.32 4.54999 2.32 5.17999 2.71 5.56999L4.68 7.53999C3.06 8.82999 1.77 10.53 1 12.5C2.73 16.89 7 20 12 20C13.52 20 14.97 19.7 16.31 19.18L19.03 21.9C19.42 22.29 20.05 22.29 20.44 21.9C20.83 21.51 20.83 20.88 20.44 20.49L4.13 4.15999C3.74 3.76999 3.1 3.76999 2.71 4.15999ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 11.73 7.18 11 7.49 10.36L9.06 11.93C9.03 12.11 9 12.3 9 12.5C9 14.16 10.34 15.5 12 15.5C12.2 15.5 12.38 15.47 12.57 15.43L14.14 17C13.49 17.32 12.77 17.5 12 17.5ZM14.97 12.17C14.82 10.77 13.72 9.67999 12.33 9.52999L14.97 12.17Z" />
                </svg>
            );

        case "dropdown-arrow":
            return (
                <svg
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    style={{ fill: fillColor }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M15.88 9.29L12 13.17L8.11998 9.29C7.72998 8.9 7.09998 8.9 6.70998 9.29C6.31998 9.68 6.31998 10.31 6.70998 10.7L11.3 15.29C11.69 15.68 12.32 15.68 12.71 15.29L17.3 10.7C17.69 10.31 17.69 9.68 17.3 9.29C16.91 8.91 16.27 8.9 15.88 9.29Z" />
                </svg>
            );
        default:
            return "";
    }
}

Icon.propTypes = {
    variant: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
